import logo from './logo.svg';
import './App.css';
import { SignInSide } from "./components";

function App()
{
	const HandleAdminSignIn = () =>
	{
		//check if there is a login url specified in the window object
		if (window.loginUrl)
		{
			//redirect to google sign in
			window.location.href = window.loginUrl;
		}
		else
		{
			//redirect to google sign in
			window.location.href = "/login/google";
		}
	};

	const unauthorized = window.unauthorized || false;

	return (
		<SignInSide HandleAdminSignIn={HandleAdminSignIn} unauthorized={unauthorized} />
	);
}

export default App;
