import { Typography, Link } from "@mui/material";
function Copyright(props)
{
	const startYear = 2023;
	const currentYear = new Date().getFullYear();

	const displayYear = (startYear === currentYear) ? startYear : `${startYear} - ${currentYear}`;

	return (
		<Typography variant="body2" color="text.secondary" align="center" {...props}>
			{'Copyright © '}
			<Link color="inherit" href="https://wearemidfield.com">
				Midfield Interactive
			</Link>{' '}
			{displayYear}
			{'.'}
		</Typography>
	);
}

export default Copyright;